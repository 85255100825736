import React from "react";
import { FaCode, FaHtml5, FaShoppingCart,FaPlug,FaCubes,FaMobileAlt,FaLaptopCode,FaGlobe } from "react-icons/fa";
import ServiceItem from "./ServiceItem";

const serviceData = [
  {
    id: 1,
    icon: <FaHtml5 />,
    title: "Web Design",
    description:
      "As one of the leading service providers of unique responsive web design services, we offer you a whole range of choices in web design. Our services include both fresh design and redesigning services and we have the experience of designing the websites of many leading players in the industry.",
  },
  {
    id: 2,
    icon: <FaCode />,
    title: "Web Development",
    description:
      "Website design might be the attraction to get more visitors to your website. If you are thinking on what can make a design work, it is the team that is developing your website. You need a web development that will work hand in hand with the frontend design to provide you responsive website.",
  },
  {
    id: 3,
    icon: <FaShoppingCart />,
    title: "E-commerce Development",
    description:
      "As a company that strives to provide comprehensive solutions, we offer our customers an ecommerce site with end to end features which will help in the growth of your business. With an unmatched online user experience, you can trust that your business gets more traffic and potentially more conversions as well.",
  },
  {
    id: 4,
    icon: <FaPlug />,
    title: "WP Plugin Development ",
    description:
      "Expand your wordpress powered website to more customization and open up its capabilities further with the WordPress plugin development services with me. There is no doubt on using WordPress as the platform for your website, despite the industry you are in. i can develop any type on WordPress plugin for your WordPress website. ",
  },
  {
    id: 5,
    icon: <FaCubes />,
    title: "Custom Web Application Development",
    description:
      "Creating web applications that are tailored to perfection to suit every industry and any business is always the specialty of Ninja Web Coders. We continue to meet and exceed the expectations of our clients and the increasing industry demands. A team of highly qualified and skilled professionals work to create modules that are high in performance, security and usability.",
  },
  {
    id: 6,
    icon: <FaMobileAlt />,
    title: "Mobile App Development",
    description:
      "It is not enough to have a successful website for your online market to boom, but you rather need to get the mobile users also on your side. This is where you need the support of mobile apps that will ensure that your customers can access your website on the go! . The apps we design and develop has the tendency to integrate multiple workflows in the enterprise.",
  },
  
  {
    id: 7,
    icon: <FaLaptopCode />,
    title: "Front-End Development",
    description:
      "Front-end web development, also known as client-side development is the practice of producing HTML, CSS and JavaScript for a website or Web Application so that a user can see and interact with them directly. The challenge associated with front end development is that the tools and techniques used to create the front end of a website change constantly and so the developer needs to constantly be aware of how the field is developing.",
  },
  {
    id: 8,
    icon: <FaGlobe />,
    title: "SEO & Digital Marketing",
    description:
      "Enjoy top ranks in every search engine and the best leads using our technical expertise and our top quality white hat SEO practices. With experience and exposure in handling SEO services for different companies in multiple disciplines and different industries, We use our experience and the knowledge we gain from our exposure to build on our SEO solutions and we guarantee you will climb to the top ranks using the natural, original and legal White hat SEO practices.",
  }
];

const Service = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {serviceData.map((service, id) => (
          <ServiceItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Service;
