import React from "react";
import Skills from "./Skills/Skills";

const About = () => {
  return (
    <section className="py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">Who am I?</h3>
            <p className="text-sm text-gray-400 leading-6 mb-3 text-justify">
            I am a TOP RATED Web Developer with over 10+ years of industry experience under my belt. I love doing work that requires out-of-the-box thinking and has complex logical requirements. I loved coding from my school days and converted my passion into a profession. I am comfortable working with both existing and new projects.
    </p>
            <p className="text-sm text-gray-400 leading-6 mb-3 text-justify">
            I always go beyond the client's expectations and work until the customer is fully satisfied. My main goal is to provide an affordable solution that will fit your needs, attract more customers to your business and make it easy to maintain without special knowledge.
I work across all industries and with small, medium and large businesses.
            </p>
            
          </div>
          
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default About;
