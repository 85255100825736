import React from "react";

const ServiceItem = (props) => {
  const { icon, title, description } = props.service;
  return (
    <div className="w-full lg:w-1/2 text-center">
      <div className="my-4 md:mx-4 shadow p-6 rounded-md bg-white group hover:shadow-md ">
        <div className="w-100 h-16 flex items-center justify-center rounded-md text-5xl mb-5  text-purple-600 transition duration-200 group-hover:bg-white-600 group-hover:text-black text-center">
          {icon}
        </div>
        <h3 className="text-lg font-medium text-gray-800 mb-2">{title}</h3>
        <p className="text-gray-400 text-justify">{description}</p>
      </div>
    </div>
  );
};

export default ServiceItem;
