import React from "react";
import {
  FaFacebookF,

  FaLinkedinIn,
  FaMobileAlt,

  FaWhatsapp,
  FaSkype,
} from "react-icons/fa";
import {SiUpwork} from "react-icons/si";

const socials = [
  {
    id: 1,
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/TheBestKiranBachhhav",
  },  
  {
    id: 2,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/kiran-bachhav/",
  }
];
const Contact = () => {
  return (
    <section className="pb-10">
      <div className=" flex-wrap md:px-4 col-md-12">
        <form className="p-6 md:mx-4 bg-white rounded-md shadow-md">
          <div className="m-3">
            <h3 className="text-2xl text-gray-800 font-bold mb-6">
              Get in Touch
            </h3>
          </div>
          <div className="w-full flex flex-wrap md:w-12/2">
       
          <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
           <li className="m-2">
              <a href="tel:+917507483108" className="w-8 h-8 bg-purple-100 rounded text-vlack-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaMobileAlt style={{float: 'left'}}/>
              </a>
            </li>
            <li className="m-2">
              <a href="https://wa.me/7507483108" className="w-8 h-8  bg-green-100 rounded text-purple-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaWhatsapp style={{float: 'left'}}/>
              </a>
            </li>
            <li className="m-2">
              <a href="skype:kiran_bachhav?chat" className="w-8 h-8  bg-blue-100 rounded text-blue-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaSkype style={{float: 'left'}}/>
              </a>
            </li>
           
            </ul>
            
          </div>
       
         
        </form>
      </div>
    </section>
  );
};

export default Contact;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        className="w-8 h-8 bg-purple-100 rounded text-purple-800 flex items-center justify-center hover:text-white hover:bg-purple-600"
      >
        {icon}
      </a>
    </li>
  );
};