import React from "react";
import ambachtscreme from "../../../images/portfolio/ambachtscreme.png";
import bagfactory from "../../../images/portfolio/bagfactory.png";
import blueribbonpetboarding from "../../../images/portfolio/blue-ribbon-pet-boarding.png";
import dblawtx from "../../../images/portfolio/dblawtx.png";
import flyosea from "../../../images/portfolio/flyosea.png";
import goldenestateinvestors from "../../../images/portfolio/goldenestateinvestors.png";
import growforfree from "../../../images/portfolio/growforfree.png";
import Italianenthusiast from "../../../images/portfolio/Italian-enthusiast.png";
import listing from "../../../images/portfolio/listing.png";
import macariobuendia from "../../../images/portfolio/macariobuendia.png";
import mmiti from "../../../images/portfolio/mmiti.png";
import mousewithhouse from "../../../images/portfolio/mousewithhouse.png";
import nikas from "../../../images/portfolio/nikas.png";
import nordst from "../../../images/portfolio/nordst.png";
import phuketsurgery from "../../../images/portfolio/phuket-surgery.png";

import prestigecustomawards from "../../../images/portfolio/prestige-custom-awards.png";
import singaporeonlineeducation from "../../../images/portfolio/singapore-online-education.png";
import southernilcabins from "../../../images/portfolio/southernilcabins.png";
import wandw from "../../../images/portfolio/wandw.png";

import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: ambachtscreme,
    title: "Ambachtscreme",
    link: "https://ambachtscreme.nl/",
    description:"",
  },
  {
    id: 2,
    image: bagfactory,
    title: "Bag Factory Matchory",
    link: "https://bag-factory.matchory.com/",
    description:"",
  },
  {
    id: 3,
    image: macariobuendia,
    title: "Macario BuenDía",
    link: "https://www.macariobuendia.com/",
    description:""
  },
  {
    id: 4,
    image: blueribbonpetboarding,
    title: "Blue Ribbon Pet Boarding",
    link: "https://www.blueribbonpetboarding.com/",
    description:"",
  },
  {
    id: 5,
    image: dblawtx,
    title: "DB Law",
    link: "https://dblawtx.com/",
    description:"",
  },
  {
    id: 6,
    image: flyosea,
    title: "Flyosea",
    link: "https://www.flyoseas.com/",
    description:""
  },
  {
    id: 7,
    image: goldenestateinvestors,
    title: " Golden State Investors",
    link: "https://www.goldenstateinvestors.com/",
    description:""
  },
  {
    id: 8,
    image: growforfree,
    title: " Grow For Free",
    link: "https://www.growforfree.com/",
    description:""
  },
  {
    id: 9,
    image: Italianenthusiast,
    title: "The Italian Enthusiast",
    link: "https://www.italianenthusiast.com/",
    description:""
  },
  {
    id: 10,
    image: listing,
    title: "Mr. Jones and Me Properties",
    link: "https://www.mrjonesandmeproperties.com/",
    description:""
  },
  {
    id: 11,
    image: mmiti,
    title: "MMITI",
    link: "https://mmiti.tech/",
    description:""
  },
  {
    id: 12,
    image: mousewithhouse,
    title: "A Mouse With A House",
    link: "https://www.amousewithahouse.com.au/",
    description:""
  },
  {
    id: 13,
    image: nikas,
    title: "Nikash - Excel to Tally XML Converter",
    link: "https://nikash.in/",
    description:""
  },
  {
    id: 14,
    image: nordst,
    title: "Nordst",
    link: "http://www.nordst.dk/",
    description:""
  },
  {
    id: 15,
    image: phuketsurgery,
    title: "Phuket Surgery",
    link: "http://www.phuket.surgery/",
    description:""
  },
  {
    id: 16,
    image: wandw,
    title: "Worn and Weathered",
    link: "https://wornandweathered.com/",
    description:""
  },
  {
    id: 17,
    image: prestigecustomawards,
    title: "Prestige Custom Awards",
    link: "https://prestigecustomawards.com/",
    description:""
  },
  {
    id: 18,
    image: singaporeonlineeducation,
    title: "singapore online education",
    link: "https://www.singaporeonlineeducation.com/",
    description:""
  },
  {
    id: 19,
    image: southernilcabins,
    title: "Southern Illinois Cabins",
    link: "https://southernilcabins.com/",
    description:"",
  }
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
