import React from "react";
import {
  FaFacebookF,

  FaLinkedinIn,
  FaMobileAlt,

  FaWhatsapp,
  FaSkype
} from "react-icons/fa";
import {SiUpwork} from "react-icons/si";
import profile from "../../../images/profile.jpg";

const socials = [
  {
    id: 1,
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/TheBestKiranBachhhav",
  },  
  {
    id: 2,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/kiran-bachhav/",
  }
];

const Sidebar = () => {
  return (
    <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
      <div className="w-24 h-24 rounded-md overflow-hidden mx-auto mb-5">
        <img src={profile} alt="shafiqhammad" className="w-full" />
      </div>
      <div className="text-center">
        <h1 className="text-xl text-gray-800 font-bold mb-1">Kiran Bachhav</h1>
        <p className="text-sm text-gray-400 mb-3">
          Web Developer & Designer - Full Time Freelancer at
          <a href="#0" className="text-purple-600 pl-1">
             Upwork
          </a>
        </p>
        {/* <a
          href="#0"
          className="inline-block mb-3 rounded bg-purple-600 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-purple-800"
          download="Resume"
        >
          Download Resume
        </a> */}
        <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
           <li className="m-2">
              <a href="tel:+917507483108" className="w-8 h-8 bg-purple-100 rounded text-vlack-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaMobileAlt style={{float: 'left'}}/>
              </a>
            </li>
            <li className="m-2">
              <a href="https://wa.me/7507483108" className="w-8 h-8  bg-green-100 rounded text-purple-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaWhatsapp style={{float: 'left'}}/>
              </a>
            </li>
            <li className="m-2">
              <a href="skype:kiran_bachhav?chat" className="w-8 h-8  bg-blue-100 rounded text-blue-800 flex items-center justify-center hover:text-white hover:bg-purple-600" >
               <FaSkype style={{float: 'left'}}/>
              </a>
            </li>
         
        </ul>
      </div>
      <div className="text-start pt-4">
        <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
          About Me
        </h3>
        <p className="text-gray-400 text font-light leading-relaxed text-justify">
        I am a Top Rated Web Developer & Designer with over 10+ years of industry experience under my belt. 
        </p>
        <p className="text-gray-400 text font-light leading-relaxed text-justify">
        I have Good Experience in PHP, Laravel, Codeigniter, WordPress, React Js, Javascript, Jquery, Ajax, HTML5, CSS3 , Payment Gateway Integrations, SMS Gateway Integrations.
        </p>
      </div>
    </aside>
  );
};

export default Sidebar;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        className="w-8 h-8 bg-purple-100 rounded text-purple-800 flex items-center justify-center hover:text-white hover:bg-purple-600"
      >
        {icon}
      </a>
    </li>
  );
};
